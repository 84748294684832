import { ChatKindEnum } from '~/ts/enums/chat'
import middlewareCheckContext from '~/helpers/middlewareCheckContext'

export default defineNuxtRouteMiddleware((to) => {
    if (useError().value || !middlewareCheckContext(to)) {
        return
    }

    if (
        !to.params.kind
        || !CHAT_KINDS.includes(to.params.kind as ChatKindEnum)
    ) {
        return navigateTo({
            name: 'p-pid-chat-kind',
            params: {
                pid: to.params.pid, // Встановлено явно для обробки кейсу під час редіректу після авторизації
                kind: ChatKindEnum.New,
            },
        })
    }
})
